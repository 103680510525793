<template>
  <div>
    <!--  标题  -->
    <head-text :title="title">
      <!--  基本信息  -->
      <div slot="baseMessageCard" class="base-message-card">
        <div class="title">
          <span class="icon"></span><span class="font">基本信息</span>
        </div>
        <van-cell-group class="message-list">
          <base-message-card
            v-for="(item, index) in baseMessages"
            :content="item"
            :key="index"
            ref="bases"
          ></base-message-card>
        </van-cell-group>
      </div>
    </head-text>
    <!--  缴费信息  -->
    <pay-message-card
      :attribute="payMessage"
      ref="pay"
      :requiredPayList="requiredPayList"
    />
    <!--  提交按钮  -->
    <div class="submit-button">
      <van-button
        round
        loading-text="提交中..."
        @click="onSubmit()"
        type="info"
        :disabled="changeState === 0"
        :loading="subFlag"
        native-type="submit"
        >立即支付
      </van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/api/request'
import { getNowTimeStamp } from '@/utils/functions'
import HeadText from '@/components/common/default/HeadText'
import BaseMessageCard from '@/components/customPage/default/BaseMessageCard'
import PayMessageCard from '@/components/customPage/default/PayMessageCard'
import {
  searchStage,
  deleteItem,
  createTree,
  judgmentType
} from '@/assets/js/createTree'

export default {
  name: 'CustomPageDefault',
  components: {
    HeadText,
    BaseMessageCard,
    PayMessageCard
  },
  data() {
    return {
      title: '', //标题
      subFlag: false, //提交按钮状态
      baseMessages: [], //baseMessages, 基本信息配置
      payMessage: [], //payMessage, 缴费信息配置
      requiredPayList: [], // 必缴信息
      combination: 0, //总金额
      loading: true //loading
      //mark: "", //备注
    }
  },
  computed: {
    changeState() {
      //监听多选框状态改变事件，改变提交按键状态。
      this.combination = 0
      for (let i = 0; i < this.payMessage.length; i++) {
        if (
          this.payMessage[i].flag === '1' &&
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          this.combination += Number(this.payMessage[i].price)
        }
      }
      return this.combination
    }
  },
  methods: {
    setMessage(baseMessages, payMessages, res, lists) {
      //设置基础信息和缴费信息
      const result1 = [] //基本信息
      const result2 = [] //缴费信息
      let result3 = [] //必缴信息
      const payTypeIdSet = new Set(payMessages.map((item) => item.payTypeId))
      const flag = payTypeIdSet.size > 1
      const individualInfoIdSortOrderMapSorted =
        res.content.individualInfoIdSortOrderMap //处理基础信息排序
          .sort((display1, display2) => display1.display - display2.display)
      for (const ele of individualInfoIdSortOrderMapSorted) {
        //基本信息
        for (const iterator of baseMessages) {
          if (ele.value === iterator.id) {
            result1.push({
              model: judgmentType(iterator.type),
              label: iterator.name,
              id: iterator.id,
              value: '',
              head: iterator.type === 'select' ? iterator.name : '',
              colon: true,
              center: true,
              type: iterator.type === 'number' ? 'number' : '',
              placeholder: '请输入' + iterator.name,
              inputAlign: 'right',
              required: parseInt(iterator.nullable) === 1,
              columns:
                iterator.type === 'select'
                  ? createTree(
                      iterator.id,
                      res.content.defaultValueList,
                      lists.separateList
                    )
                  : ''
            })
          }
        }
      }
      const subjectIdSortOrderMapSorted = res.content.subjectIdSortOrderMap //处理缴费信息排序
        .sort((display1, display2) => display1.display - display2.display)
      for (let i = 0; i < payMessages.length; i++) {
        for (const iterator of payMessages) {
          //缴费信息
          if (subjectIdSortOrderMapSorted[i].value === iterator.id) {
            result2.push({
              item: iterator.name,
              price: '',
              cusid: iterator.payTypeId,
              flag: '',
              type: 'number',
              id: iterator.id,
              maxAmount: iterator.maxAmount,
              minAmount: iterator.minAmount
            })
          }
        }
      }
      result3 = flag
        ? []
        : res.content.style
        ? JSON.parse(res.content.style).requiredPayList
        : []
      return [result1, result2, result3]
    },

    cascadeHandle(res) {
      //处理级联关系
      let baseMessages, lists
      if (res.content.defaultValueList !== undefined) {
        //判断是否有级联关系
        lists = searchStage(res.content.defaultValueList)
        baseMessages = deleteItem(res.content.individualInfos, lists.mergeList)
      } else {
        baseMessages = res.content.individualInfos
      }
      return { baseMessages, lists }
    },
    judgeUA(res) {
      //判断UA
      let ua = navigator.userAgent
      if (
        ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger' || //不能改成全等,类型不一样，前面是个数组
        ua.toLowerCase().match(/Alipay/i) == 'alipay' ||
        ua.toLowerCase().match(/UnionPay/i) == 'unionpay' ||
        !/Android|webOS|iPhone|iPod|BlackBerry/i.test(ua)
      ) {
        //移动端只能在支付宝或手机打开
        this.title = res.content.name //页面标题
        let message = this.cascadeHandle(res) //基础信息
        let payMessages = res.content.subjects //缴费信息
        if (
          //判断时间范围
          getNowTimeStamp() < parseInt(res.content.startAt) ||
          getNowTimeStamp() > parseInt(res.content.endAt)
        ) {
          this.$router.replace('/error/outoftime')
          return
        }
        ;[this.baseMessages, this.payMessage, this.requiredPayList] =
          this.setMessage(message.baseMessages, payMessages, res, message.lists) //设置基础信息和缴费信息
        this.loading = false //loading
      } else {
        this.$router.replace('/error/paytype')
      }
    },
    judgeStatus(form) {
      //提交时判断输入的状态，生成表单
      let baseFlag = false,
        payFlag = false,
        errorFlag = false
      for (let i = 0; i < this.baseMessages.length; i++) {
        //整合基础信息
        form.individualList.push({
          infoId: this.baseMessages[i].id,
          infoValue: this.baseMessages[i].value
        })
        if (this.$refs.bases[i].checkContent()) {
          baseFlag = true
        }
        if (this.$refs.bases[i].errorMessage !== '') {
          errorFlag = true
        }
      }
      for (let i of this.$refs.pay.result) {
        //整合支付信息
        if (
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          //只提交选中且非空的订单
          if (
            this.payMessage[i].price.indexOf('.') + 3 >=
              this.payMessage[i].price.length ||
            this.payMessage[i].price.indexOf('.') < 0
          ) {
            //判断是否是两位小数
            form.subjectList.push({
              subjectId: this.payMessage[i].id,
              shouldPayAmount: this.payMessage[i].price
            })
          } else {
            Toast.fail('金额不能出现两位以上的小数！')
            form = 0
            break
          }
        } else {
          Toast.fail('金额不能为0或空！')
          form = 0
          break
        }
      }
      for (const iterator of this.$refs.pay.checkAmount) {
        if (iterator) {
          payFlag = true
          break
        }
      }
      return [baseFlag, payFlag, errorFlag, form]
    },

    startPay(baseFlag, payFlag, errorFlag, form) {
      //支付验证和发起支付
      if (baseFlag) {
        //信息填写检查
        Toast.fail('请确保信息填写完整')
      } else if (errorFlag) {
        //信息填写是否正确
        Toast.fail('请确保信息填写正确')
      } else if (payFlag) {
        //缴费范围检查
        Toast.fail('缴费金额不在允许的范围')
      } else {
        this.subFlag = true
        // console.log(form) //提交的订单报文
        request
          .post('payment/wx/school/order', form)
          .then((res) => {
            this.subFlag = false
            window.location.href = res.content.url + '?' + res.content.body
          })
          .catch((err) => {
            this.subFlag = false
            console.error(err)
            Toast.fail('发起支付失败')
          })
      }
    },

    onSubmit() {
      //表单提交
      const form = {
        individualList: [],
        pageCode: this.$route.params.code,
        remark: '',
        subjectList: [],
        tenantId: this.$route.params.tenantid
      }
      let a = this.judgeStatus(form)
      if (a[a.length - 1] !== 0) {
        this.startPay(...a) //支付发起
      }
    }
  },
  mounted() {
    request
      .get(
        //数据请求和数据赋值
        '/payment/wx/school/page/' +
          this.$route.params.mode +
          '/' +
          this.$route.params.tenantid +
          '/' +
          this.$route.params.code
      )
      .then((res) => {
        document.title = res.content.name
        this.judgeUA(res) //判断ua
      })
      .catch((err) => {
        console.error(err)
        Toast.fail('页面初始化失败')
      })
  }
}
</script>

<style scoped lang="less">
.base-message-card {
  width: 345px;
  padding: 5px;
  margin: auto;
  margin-top: -36px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 1px 3px #bbbbbb;

  .title {
    padding: 5px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    .icon {
      padding: 2px 0;
      width: 3px;
      height: 16px;
      display: block;
      background-color: #3975f1;
      border-radius: 2px;
    }
    .font {
      font-size: 14px;
      padding: 0 5px;
    }
  }

  .message-list {
    /deep/.van-checkbox__label {
      font-size: 12px;
    }
    /deep/.van-cell {
      padding: 5px 14px;
      font-size: 12px;
    }
  }

  /deep/[class*='van-hairline']::after {
    border: none;
  }
}
.submit-button {
  margin: 80px auto;
  width: 265px;
  height: 34px;
  font-size: 13px;
  .van-button--round {
    width: 265px;
    height: 34px;
    border-radius: 17px;
  }
}
</style>
