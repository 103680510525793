<template>
  <div>
    <!-- 输入框 -->
    <template
      v-if="content.model == 'field'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-field
        v-model="content.value"
        clickable
        :error="content.error"
        :error-message-align="content.inputAlign"
        :label="content.label"
        :type="content.type"
        :error-message="errorMessage"
        :colon="content.colon"
        :disabled="content.disabled"
        :placeholder="content.placeholder"
        :center="content.center"
        :required="content.required"
        :input-align="content.inputAlign"
        @blur="inspect"
      />
    </template>
    <!-- 单元格 -->
    <template
      v-else-if="content.model == 'cell'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-cell
        :title="content.head"
        :value="content.value"
        label="content.label"
      >
      </van-cell>
    </template>
    <!-- 级联选择 -->
    <!-- <template
          v-else-if="content.model == 'cascader'"
          class="van-hairline--top van-hairline--bottom"
        >
          <van-field
            v-model="fieldValue"
            readonly
            :required="content.required"
            :label="content.label"
            :input-align="content.inputAlign"
            :placeholder="content.placeholder"
            @click="show = true"
          />
          <van-popup v-model="show" round :position="content.position">
            <van-cascader
              v-model="content.value"
              :title="content.head"
              :options="content.options"
              @close="show = false"
              @finish="onFinish"
            />
          </van-popup>
        </template> -->
    <!-- 选择器 -->
    <template
      v-else-if="content.model == 'picker'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-field
        readonly
        :error="content.error"
        clickable
        :center="content.center"
        :required="content.required"
        :error-message="errorMessage"
        :error-message-align="content.inputAlign"
        :input-align="content.inputAlign"
        :label="content.label"
        :value="content.value"
        :placeholder="content.placeholder"
        @click="showPicker = true"
        @click-overlay="onCancel"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :title="content.head"
          :columns="content.columns"
          @cancel="onCancel"
          @confirm="onConfirm"
        />
      </van-popup>
    </template>
  </div>
</template>

<script>
export default {
  name: 'BaseMessageCard',
  props: {
    content: Object
  },
  data() {
    return {
      fieldValue: '', //输入框
      show: false, //级联
      cascaderValue: '',
      showPicker: false, //picker选择器
      pickerValue: '',
      errorMessage: '', //错误信息
      visible: true
    }
  },
  methods: {
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false
      this.fieldValue = selectedOptions.map((option) => option.text).join('/')
    },
    onConfirm(value) {
      //级联选择确认事件
      if (typeof value === 'string') {
        this.content.value = value
      } else {
        switch (value.length) {
          case 2:
            this.content.value = value[0] + ' ' + value[1]
            break
          case 3:
            this.content.value = value[0] + ' ' + value[1] + ' ' + value[2]
            break
        }
      }
      this.errorMessage = ''
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
      if (this.content.value == '') {
        this.errorMessage = this.content.label + '不能为空'
      }
    },
    checkContent() {
      //确认调用事件
      let flag = false
      if (this.content.value == '' && this.content.required) {
        flag = true
        this.errorMessage = this.content.label + '不能为空'
      }
      return flag
    },
    inspect() {
      //失焦检查
      const telphoneNumber =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      const ID_card =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      if (this.content.value === '' && this.content.required) {
        this.errorMessage = this.content.label + '不能为空'
      } else {
        switch (this.content.label) {
          case '手机号':
          case '手机号码':
            if (
              !telphoneNumber.test(this.content.value) &&
              this.content.value !== ''
            ) {
              this.errorMessage = this.content.label + '格式错误'
            } else {
              this.errorMessage = ''
            }
            break
          case '身份证号':
          case '身份证号码':
            if (
              !ID_card.test(this.content.value) &&
              this.content.value !== ''
            ) {
              this.errorMessage = this.content.label + '格式错误'
            } else {
              this.errorMessage = ''
            }
            break
          default:
            this.errorMessage = ''
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.van-field__label {
  width: calc(12px * 15);
}
</style>
