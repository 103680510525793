import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    split: false, //是否拆单
    title: '', //订单页面名称
    orderMessages: [], //所有订单信息
    orderMessage: {}, //当前的订单信息
    style: {}
  },
  mutations: {
    setStyle(state, payload) {
      state.style = payload
    },
    setSplitOrder(state, payload) {
      state.split = payload
    },
    setOrderMessages(state, payload) {
      state.orderMessages = payload
    },
    setOderMessage(state, payload) {
      state.orderMessage = payload
    },
    setTitle(state, payload) {
      state.title = payload
    }
  },
  actions: {},
  modules: {}
})
