import Vue from 'vue'

import {
  Panel,
  Switch,
  Radio,
  RadioGroup,
  NoticeBar,
  Stepper,
  Divider,
  Swipe,
  SwipeItem,
  Field,
  Button,
  CellGroup,
  Checkbox,
  Cell,
  CheckboxGroup,
  Empty,
  Tag,
  Skeleton,
  Search,
  Picker,
  Popup,
  Icon,
  List,
  Popover,
  DatetimePicker
} from 'vant'

Vue.use(Panel)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Stepper)
Vue.use(Switch)
Vue.use(NoticeBar)
Vue.use(Divider)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Field)
Vue.use(Button)
Vue.use(CellGroup)
Vue.use(Checkbox)
Vue.use(Cell)
Vue.use(CheckboxGroup)
Vue.use(Empty)
Vue.use(Tag)
Vue.use(Skeleton)
Vue.use(Search)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Icon)
Vue.use(List)
Vue.use(Popover)
Vue.use(DatetimePicker)

export default Vue
