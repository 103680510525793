const Axios = require('axios')

const request = Axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 8000
})

request.interceptors.request.use(
  (req) => {
    return req
  },
  (error) => {
    console.error(error)
    return error
  }
)

request.interceptors.response.use(
  (res) => {
    if (res.status === 200 && res.data.code === 'success') {
      return res.data
    } else {
      return Promise.reject(res.data)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)
export default request
