<template>
  <div class="pay-message-card">
    <div class="title">
      <span class="icon"></span><span class="font">账单信息</span>
      <div class="titleSwitch">
        <van-stepper
          theme="round"
          @change="getNumberCount"
          style="margin-left: 15px"
          v-model="value"
          size="20px"
        />
      </div>
    </div>
    <van-cell-group class="message-list">
      <van-checkbox-group
        v-model="result"
        v-for="(item, index) in attribute"
        :key="index"
        class="van-hairline--bottom"
      >
        <van-field
          clickable
          input-align="right"
          :disabled="checkIsOk[index]"
          @focus="selectedItem(index)"
          v-model="item.price"
          :type="item.type"
          :name="item.item"
          :error-message="checkAmount[index] ? '输入的金额不在允许范围' : ''"
          placeholder="请输入金额"
        >
          <template #label @click="toggle(index)">
            <van-checkbox
              :name="index"
              ref="checkboxes"
              :disabled="checkIsOk[index] || checkRequired(item.id)"
              >{{ item.item }}</van-checkbox
            >
          </template>
          <template #button>
            <span>元</span>
          </template>
        </van-field>
      </van-checkbox-group>
      <!-- 缴费合计 -->
      <div class="sum-amount">
        <span>合计：{{ sumAmount }}</span>
      </div>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'PayMessageCard',
  props: {
    attribute: Array,
    requiredPayList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      selectPayMethods: '', //选中的支付方法
      result: [] //选中的选项
    }
  },
  computed: {
    checkAmount() {
      //检查输入的值是否在允许的范围内
      let results = []
      for (const iterator of this.attribute) {
        console.log('this/attribute', iterator)
        if (
          (iterator.price > 0 &&
            iterator.price >= iterator.minAmount &&
            iterator.price <= iterator.maxAmount) ||
          iterator.price === ''
        ) {
          //在范围
          results.push(false)
          if (iterator.maxAmount === iterator.minAmount) {
            //上下限相等时设置默认值
            iterator.price = iterator.minAmount.toString()
          }
        } else {
          results.push(true)
        }
      }
      return results
    },
    sumAmount() {
      //计算缴费总计
      let sumNum = 0
      for (let i = 0; i < this.attribute.length; i++) {
        if (this.result.indexOf(i) != -1)
          sumNum += Number(this.attribute[i].price)
      }
      return sumNum.toFixed(2) + ' 元'
    },
    checkIsOk() {
      //检查多选框状态
      let state = []
      for (let i = 0; i < this.attribute.length; i++) {
        this.attribute[i].flag = ''
        if (this.result.length > 0) {
          state.push(
            this.attribute[this.result[0]].cusid != this.attribute[i].cusid
          )
        }
      }
      for (let i = 0; i < this.result.length; i++) {
        this.attribute[this.result[i]].flag = '1'
      }
      return state
    }
  },
  methods: {
    checkRequired(id) {
      for (const idElement of this.requiredPayList) {
        const ids = this.attribute.map((item) => item.id)
        const a = ids.indexOf(idElement)
        const index = this.result.indexOf(a)
        if (index < 0) {
          this.result.push(a)
        }
      }
      return this.requiredPayList.indexOf(id) > -1
    },
    selectedItem(index) {
      //自动勾选
      if (this.result.indexOf(index) < 0) {
        this.result.push(index)
      }
    },
    resetCount() {
      for (let i = 0; i < this.attribute.length; i++) {
        if (this.result.indexOf(i) != -1) {
          this.attribute[i].price = 100
        }
      }
    },
    getNumberCount(value) {
      console.log('values', value)
      for (let i = 0; i < this.attribute.length; i++) {
        if (this.result.indexOf(i) != -1) {
          this.attribute[i].price = 100
          this.attribute[i].price *= value
          console.log('99', this.attribute[i].price)
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.pay-message-card {
  width: 345px;
  padding: 5px;
  margin: auto;
  margin-top: 10px;
  border-radius: 5px;
  background-color: white;
  box-shadow: 1px 1px 3px #bbbbbb;

  .title {
    padding: 5px;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    .titleSwitch {
      display: flex;
      justify-content: space-between;
    }
    .icon {
      padding: 2px 0;
      width: 3px;
      height: 16px;
      display: block;
      background-color: #3975f1;
      border-radius: 2px;
    }

    .font {
      font-size: 14px;
      padding: 0 5px;
    }
  }

  .message-list {
    /deep/ .van-checkbox__label {
      font-size: 12px;
    }

    /deep/ .van-field__label {
      width: calc(12px * 15);
    }

    /deep/ .van-cell {
      padding: 5px 14px;
      font-size: 12px;
    }
  }

  .sum-amount {
    border-top: 1px solid #e5e5e5;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin: 10px;
    margin-bottom: 0;
    padding: 5px;
    padding-bottom: 0;
    color: #ff5151;
  }

  /deep/ [class*='van-hairline']::after {
    border: none;
  }
}
</style>
