<template>
  <div>
    <div class="head">
      <div class="title van-ellipsis">{{ title }}</div>
    </div>
    <template class="card">
      <slot name="card"></slot>
    </template>
    <template class="base-message-card">
      <slot name="baseMessageCard"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: 'HeadText',
  props: {
    title: String
  },
  mounted() {
    document.getElementsByTagName('body')[0].style.margin = '0'
  }
}
</script>

<style scoped lang="less">
.head {
  background-image: url('~@/assets/img/orderpage/default/searchbackground.png');
  background-size: cover;
  width: 375px;
  height: 180px;

  .title {
    margin-left: 28px;
    padding-top: 67px;
    font-size: 25px;
    font-weight: normal;
    font-stretch: normal;
    color: #ffffff;
  }
}
.card {
  width: 345px;
  height: 132px;
  padding: 5px;
  margin: auto;
  margin-top: -36px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 2px 3px #bbbbbb;
}
.base-message-card {
  width: 345px;
  padding: 5px;
  margin: auto;
  margin-top: -36px;
  background-color: white;
  border-radius: 5px;
}
</style>
