let order = [
  {
    path: '/simple/:templateId/searchpage',
    component: () => import('@/components/orderPage/simple/SearchPage.vue')
  },
  {
    path: '/simple/:templateId/orderpage',
    component: () => import('@/views/orderPage/simple/index.vue')
  },
  {
    path: '/default/:templateId/searchpage',
    component: () => import('@/components/orderPage/default/SearchPage.vue')
  },
  {
    path: '/default/:templateId/orderpage',
    component: () => import('@/views/orderPage/default/index.vue')
  }
]

export default order
