<template>
  <div class="orderPage">
    <head-text :title="this.$store.state.title"></head-text>
    <!--  页面主体  -->
    <van-empty v-show="!showFlag" description="暂无信息" />
    <div class="body" v-show="showFlag">
      <!--   基础信息   -->
      <div class="base-message">
        <van-cell-group
          title="基本信息"
          class="van-hairline--top van-hairline--bottom"
        >
          <van-cell
            :title="info[index]"
            v-for="(item, index) in info"
            :key="item"
          />
          <!--           value="内容"-->
        </van-cell-group>
      </div>
      <!-- 缴费信息 -->
      <div class="pay-message">
        <van-cell-group
          title="缴费信息"
          class="van-hairline--top van-hairline--bottom"
        >
          <van-cell
            title="收费项"
            :value="subject[index] + '￥'"
            v-for="(item, index) in subject"
            :key="item"
          />
        </van-cell-group>
      </div>
      <van-cell title="合计" :value="sumAmount" value-class="colorSet" />
      <!--   提交按钮   -->
      <div class="submit-button">
        <van-button
          round
          block
          loading-text="提交中..."
          size="large"
          @click="onSubmit()"
          type="info"
          :loading="subFlag"
          :disabled="disabledBtn"
          native-type="submit"
          >提交
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import request from '@/api/request'
import HeadText from '@/components/common/simple/HeadText.vue'
// import PaySellect from '@/components/orderPage/simple/PaySellect.vue'
// import ListCell from '@/components/orderPage/simple/ListCell'

export default {
  name: 'OrderPageSimple',
  components: {
    HeadText
    // PaySellect,
    // ListCell
  },
  data() {
    return {
      showFlag: false, //true显示体，false显示暂无信息
      subFlag: false, //提交按钮状态
      baseMessages: [], //baseMessages, 基本信息配置
      payMessage: [], //payMessage, 缴费信息配置
      combination: 0, //总金额
      loading: true, //loading
      info: {},
      subject: {},
      timeStamp: '',
      sumAmount: '',
      disabledBtn: false
    }
  },
  computed: {
    changeState() {
      //监听多选框状态改变事件，改变提交按键状态。
      this.combination = 0
      for (let i = 0; i < this.payMessage.length; i++) {
        if (
          this.payMessage[i].flag === '1' &&
          this.payMessage[i].price !== '' &&
          parseFloat(this.payMessage[i].price) > 0
        ) {
          this.combination += Number(this.payMessage[i].price)
        }
      }
      return this.combination
    }
  },
  methods: {
    onSubmit() {
      let dateNow = new Date().valueOf()
      if (dateNow - this.timeStamp > 300000) {
        Toast.fail('二维码已过期')
        this.disabledBtn = true
        return
      }
      //表单提交事件
      let infoArr = []
      let subjArr = []
      for (let i in this.info) {
        let infoItem = {}
        infoItem.infoId = i
        infoItem.infoValue = this.info[i]
        infoArr.push(infoItem)
      }
      for (let j in this.subject) {
        let subjItem = {}
        subjItem.subjectId = j
        subjItem.shouldPayAmount = this.subject[j]
        subjArr.push(subjItem)
      }
      const form = {
        individualList: infoArr,
        subjectList: subjArr,
        pageCode: this.$route.params.pagecode,
        tenantId: this.$route.params.tenantid
      }
      this.subFlag = true
      // let a = this.judgeStatus(form)
      // if (a[0] !== 0) {
      // this.startPay() //支付发起
      request
        .post('/payment/wx/school/order', form)
        .then((res) => {
          this.subFlag = false
          window.location.href = res.content.url + '?' + res.content.body
        })
        .catch((err) => {
          this.subFlag = false
          console.error(err)
          Toast.fail(err.message)
        })
      // }
    }
  },
  mounted() {
    console.log(
      'monted',
      process.env.VUE_APP_QUERY_MSG,
      this.$axios,
      this.$route
    )
    let code = this.$route.params.code
    this.$axios
      .get(process.env.VUE_APP_QUERY_MSG + `?param=${code}`)
      .then((res) => {
        if (typeof res.data === 'string' && res.data === 'key已过期') {
          this.disabledBtn = true
          Toast.fail('二维码已过期')
        } else {
          let dataSource = res.data
          this.info = dataSource.info
          this.subject = dataSource.subject
          this.timeStamp = dataSource.timeStamp
          let sumNum = 0
          for (let i in this.subject) {
            console.log('111', this.subject[i])
            sumNum += Number(this.subject[i])
          }
          this.sumAmount = sumNum.toFixed(2) + ' 元'
        }

        console.log('res', res)
      })
      .catch((error) => {
        console.log(error)
      })
    this.showFlag = true
    console.log(this.$route)

    // console.log(dataSource, 'dataSource')

    // if (this.$store.state.orderMessages.length > 0) {
    //   for (const argument of this.$store.state.orderMessage.baseMessage) {
    //     this.baseMessages.push({
    //       label: argument.value,
    //       value: argument.display
    //     })
    //   }
    //   this.payMessage = this.$store.state.orderMessage.payMessage
    // }
  }
}
</script>

<style scoped lang="less">
@import '~@less/common.less';
.orderPage {
  .van-search__content {
    border: #1995fa solid 1px;
  }
  .body {
    padding: 0 10px;

    .pay-message {
      .message(0 0 10px 10px);
    }

    .submit-button {
      margin: 30px 20px;
    }
  }
}
.colorSet {
  color: #ff6600;
}
</style>
