<template>
  <div title>
    <van-checkbox-group
      v-model="result"
      v-for="(item, index) in attribute"
      :key="index"
      class="van-hairline--bottom"
    >
      <van-field
        clickable
        input-align="right"
        :disabled="checkIsOk[index]"
        @focus="selectedItem(index)"
        v-model="item.price"
        :type="item.type"
        :name="item.item"
        :error-message="checkAmount[index] ? '输入的金额不在允许范围' : ''"
        placeholder="请输入金额"
      >
        <template #label @click="toggle(index)">
          <van-checkbox
            :name="index"
            ref="checkboxes"
            :disabled="checkIsOk[index]"
            >{{ item.item }}</van-checkbox
          >
        </template>
        <template #button>
          <span>元</span>
        </template>
      </van-field>
    </van-checkbox-group>
    <!-- 缴费合计 -->
    <van-cell-group class="van-hairline--top van-hairline--bottom">
      <van-cell
        title="缴费合计："
        :value="sumAmount"
        title-class="amount-title"
        value-class="amount-value"
      ></van-cell>
    </van-cell-group>
  </div>
</template>

<script>
export default {
  name: 'PaySellect',
  props: {
    attribute: Array,
    title: String
  },
  data() {
    return {
      result: [] //选中的选项
    }
  },
  computed: {
    checkAmount() {
      //检查输入的值是否在允许的范围内
      let results = []
      for (const iterator of this.attribute) {
        if (
          (iterator.price > 0 &&
            iterator.price >= iterator.minAmount &&
            iterator.price <= iterator.maxAmount) ||
          iterator.price === ''
        ) {
          //在范围
          results.push(false)
          if (iterator.maxAmount === iterator.minAmount) {
            //上下限相等时设置默认值
            iterator.price = iterator.minAmount.toString()
          }
        } else {
          results.push(true)
        }
      }
      return results
    },
    sumAmount() {
      //计算缴费总计
      let sumNum = 0
      for (let i = 0; i < this.attribute.length; i++) {
        if (this.result.indexOf(i) != -1)
          sumNum += Number(this.attribute[i].price)
      }
      return sumNum.toFixed(2) + ' 元'
    },
    checkIsOk() {
      //检查多选框状态
      let state = []
      for (let i = 0; i < this.attribute.length; i++) {
        this.attribute[i].flag = ''
        if (this.result.length > 0) {
          state.push(
            this.attribute[this.result[0]].cusid != this.attribute[i].cusid
          )
        }
      }
      for (let i = 0; i < this.result.length; i++) {
        this.attribute[this.result[i]].flag = '1'
      }
      return state
    }
  },
  methods: {
    selectedItem(index) {
      //自动勾选
      if (this.result.indexOf(index) < 0) {
        this.result.push(index)
      }
    }
  }
}
</script>

<style scoped lang="less">
.amount-title {
  color: #ed6a0c;
  font-size: 16px;
  font-family: emoji;
  font-weight: bold;
}
.amount-value {
  color: black;
  font-size: 18px;
  font-family: emoji;
  font-weight: bold;
}
</style>
