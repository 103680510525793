<template>
  <div id="error">
    <van-empty
      image="error"
      image-size="180px"
      class="empty"
      :description="
        this.$route.params.type === 'paytype'
          ? '请使用微信支付宝或云闪付打开'
          : '此缴费页面未在规定的日期范围内'
      "
    />
  </div>
</template>

<script>
export default {
  name: 'Error',
  data() {
    return {}
  },
  computed: {}
}
</script>

<style scoped lang="less">
.empty {
  margin-top: 40%;
}
</style>
