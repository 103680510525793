<template>
  <div id="stylecell">
    <!-- 输入框 -->
    <template
      v-if="informations.model == 'field'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-field
        v-model="informations.value"
        clickable
        :error="informations.error"
        :error-message-align="informations.inputAlign"
        :label="informations.label"
        :type="informations.type"
        :error-message="errorMessage"
        :colon="informations.colon"
        :disabled="informations.disabled"
        :placeholder="informations.placeholder"
        :center="informations.center"
        :required="informations.required"
        :input-align="informations.inputAlign"
        @blur="inspect"
      />
    </template>
    <!-- 单元格 -->
    <template
      v-else-if="informations.model == 'cell'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-cell
        :title="informations.head"
        :value="informations.value"
        label="informations.label"
      >
      </van-cell>
    </template>
    <!-- 级联选择 -->
    <!-- <template
      v-else-if="informations.model == 'cascader'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-field
        v-model="fieldValue"
        readonly
        :required="informations.required"
        :label="informations.label"
        :input-align="informations.inputAlign"
        :placeholder="informations.placeholder"
        @click="show = true"
      />
      <van-popup v-model="show" round :position="informations.position">
        <van-cascader
          v-model="informations.value"
          :title="informations.head"
          :options="informations.options"
          @close="show = false"
          @finish="onFinish"
        />
      </van-popup>
    </template> -->
    <!-- 选择器 -->
    <template
      v-else-if="informations.model == 'picker'"
      class="van-hairline--top van-hairline--bottom"
    >
      <van-field
        readonly
        :error="informations.error"
        clickable
        :center="informations.center"
        :required="informations.required"
        :error-message="errorMessage"
        :error-message-align="informations.inputAlign"
        :input-align="informations.inputAlign"
        :label="informations.label"
        :value="informations.value"
        :placeholder="informations.placeholder"
        @click="showPicker = true"
        @click-overlay="onCancel"
      />
      <van-popup v-model="showPicker" round position="bottom">
        <van-picker
          show-toolbar
          :title="informations.head"
          :columns="informations.columns"
          @cancel="onCancel"
          @confirm="onConfirm"
        />
      </van-popup>
    </template>
  </div>
</template>

<script>
export default {
  name: 'StyleCell',
  props: {
    informations: Object
  },
  data() {
    return {
      fieldValue: '', //输入框
      show: false, //级联
      cascaderValue: '',
      showPicker: false, //picker选择器
      pickerValue: '',
      errorMessage: '', //错误信息
      visible: true
    }
  },
  methods: {
    // 全部选项选择完毕后，会触发 finish 事件
    onFinish({ selectedOptions }) {
      this.show = false
      this.fieldValue = selectedOptions.map((option) => option.text).join('/')
    },
    onConfirm(value) {
      //级联选择确认事件
      if (typeof value === 'string') {
        this.informations.value = value
      } else {
        switch (value.length) {
          case 2:
            this.informations.value = value[0] + ' ' + value[1]
            break
          case 3:
            this.informations.value = value[0] + ' ' + value[1] + ' ' + value[2]
            break
        }
      }
      this.errorMessage = ''
      this.showPicker = false
    },
    onCancel() {
      this.showPicker = false
      if (this.informations.value == '') {
        this.errorMessage = this.informations.label + '不能为空'
      }
    },
    checkContent() {
      //确认调用事件
      let flag = false
      if (this.informations.value == '' && this.informations.required) {
        flag = true
        this.errorMessage = this.informations.label + '不能为空'
      }
      return flag
    },
    inspect() {
      //失焦检查
      const telphoneNumber =
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
      const ID_card =
        /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/
      if (this.informations.value === '' && this.informations.required) {
        this.errorMessage = this.informations.label + '不能为空'
      } else {
        switch (this.informations.label) {
          case '手机号':
          case '手机号码':
            if (
              !telphoneNumber.test(this.informations.value) &&
              this.informations.value !== ''
            ) {
              this.errorMessage = this.informations.label + '格式错误'
            } else {
              this.errorMessage = ''
            }
            break
          case '身份证号':
          case '身份证号码':
            if (
              !ID_card.test(this.informations.value) &&
              this.informations.value !== ''
            ) {
              this.errorMessage = this.informations.label + '格式错误'
            } else {
              this.errorMessage = ''
            }
            break
          default:
            this.errorMessage = ''
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
#stylecell {
  border-radius: 2px;
}
</style>
