import Vue from 'vue'
import VueRouter from 'vue-router'
import Error from '../views/error/Error.vue'
import custom from './modules/custom.js'
import order from './modules/order.js'
Vue.use(VueRouter)
let index = [
  /*页面路径 */
  {
    path: '*',
    component: () => import('../views/404/404.vue')
  },
  {
    path: '/',
    component: () => import('../views/404/404.vue')
  },
  {
    path: '/error/:type',
    component: Error
  }
]
let routes = index.concat(order, custom)

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
