import CustomPageSimple from '@/views/customPage/simple/index.vue'
import CustomPageDefault from '@/views/customPage/default/index.vue'
import CustomPageStatic from '@/views/customPage/static/index.vue'
import BearPageDefault from '@/views/customPage/bear/index.vue'

let custom = [
  {
    path: '/bear/:tenantid/:code',
    component: BearPageDefault
  },
  {
    path: '/simple/:tenantid/:code',
    component: CustomPageSimple
  },
  {
    path: '/default/:tenantid/:code',
    component: CustomPageDefault
  },
  {
    path: '/surl/:code/:tenantid/:pagecode',
    component: CustomPageStatic
  }
]
export default custom
