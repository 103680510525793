function getNowTimeStamp() {
  // 获取当前时间戳

  let date = new Date()
  return date.getTime()
}

function judgeUA(router) {
  //判断UA
  let ua = navigator.userAgent
  if (
    !(
      ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger' || //不能改成全等,类型不一样，前面是个数组
      ua.toLowerCase().match(/Alipay/i) == 'alipay' ||
      ua.toLowerCase().match(/UnionPay/i) == 'unionpay'
    )
  ) {
    router.replace('/error/paytype')
  }
  return ua.toLowerCase().match(/MicroMessenger/i) == 'micromessenger'
}

export { getNowTimeStamp, judgeUA }
