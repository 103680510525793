import Vue from './assets/vant-components/index.js'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'

const isDebug_mode = !(
  process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging'
) //关闭开发工具
Vue.config.debug = isDebug_mode
Vue.config.devtools = isDebug_mode
Vue.config.productionTip = isDebug_mode
Vue.prototype.$axios = axios

// if (process.env.NODE_ENV === 'development') {
//   import('./mockjs/index')
// }
new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
