function judgmentType(type) {
  //判断输入框的类型
  switch (type) {
    case 'select':
      return 'picker'
    default:
      return 'field'
  }
}

function getCurrentData() {
  //获取今天日期
  const date = new Date()
  let year = date.getFullYear().toString()
  let month =
    date.getMonth() + 1 < 10
      ? '0' + (date.getMonth() + 1).toString()
      : (date.getMonth() + 1).toString()
  let day =
    date.getDate() < 10
      ? '0' + date.getDate().toString()
      : date.getDate().toString()
  return parseInt(year + month + day)
}

function searchChildren(tree, child) {
  //查找父子关系
  child.push(tree.infoId)
  if (tree.children) {
    searchChildren(tree.children[0], child)
  } else {
    return 0
  }
}

function searchStage(tree_list) {
  //将查找到的父子关系整合成二维数组
  let lists = []
  for (const iterator of tree_list) {
    let list = []
    searchChildren(iterator, list)
    if (lists.length < 1) {
      lists.push(list)
    } else {
      let flag = true
      for (let i = 0; i < lists.length; i++) {
        if (lists[i][0] === list[0]) {
          flag = false
          if (lists[i].length <= list.length) {
            lists[i] = list
            break
          }
        }
      }
      if (flag) {
        lists.push(list)
      }
    }
  }
  let result = []
  for (let i = 0; i < lists.length; i++) {
    result = result.concat(lists[i].slice(0, -1))
  }
  return { mergeList: result, separateList: lists } //要剔除的数据
}

function deleteItem(initItem, lists) {
  //获得要删除的列表(原始数据，searchStage返回的结果)
  const initItemCopy = initItem.concat()
  let individualInfoList = []
  for (const list of initItemCopy) {
    individualInfoList.push(list.id)
  }
  let deleteItem = []
  for (const list of lists) {
    deleteItem.push(individualInfoList.indexOf(list))
  }
  for (const deleteItemElement of deleteItem) {
    delete initItemCopy[deleteItemElement]
  }
  let results = []
  for (const deleteItemElement of initItemCopy) {
    if (deleteItemElement) {
      results.push(deleteItemElement)
    }
  }
  return results
}

function initTree(initTree, stage) {
  //处理好森林中的每一棵树
  if (stage === 1) {
    //1级
    return initTree.defaultValue
  } else {
    let tree = {}
    tree.text = initTree.defaultValue
    if (initTree.children) {
      Object.assign(tree, { children: [] })
      for (let i = 0; i < initTree.children.length; i++) {
        //2级
        tree.children.push({ text: initTree.children[i].defaultValue })
        if (stage >= 3) {
          //3级
          Object.assign(tree.children[i], { children: [] })
          if (initTree.children[i].children) {
            for (const child of initTree.children[i].children) {
              tree.children[i].children.push({ text: child.defaultValue })
            }
          } else {
            tree.children[i].children.push({ text: '' })
          }
        }
      }
    } else {
      Object.assign(tree, { children: [] })
      tree.children.push({ text: '' })
    }
    return tree
  }
}

function createTree(id, treeList, separateList) {
  //创建级联树(级联选择的id，关系树，原始数据，父子关系二维数组)
  console.log(id, treeList, separateList)
  let index //存储父子关系索引
  for (const separateListElement of separateList) {
    //找到对应的父子关系
    if (separateListElement[separateListElement.length - 1] === id) {
      index = separateListElement
    }
  }
  const columns = []
  for (const treeListElement of treeList) {
    //找到所有有此类型索引的项目
    if (treeListElement.infoId === index[0]) {
      columns.push(initTree(treeListElement, index.length))
    }
  }
  return columns
}
export { getCurrentData, searchStage, deleteItem, createTree, judgmentType }
