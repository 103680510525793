<template>
  <div class="head-text">
    <!--  提示标语  -->
    <!-- <van-notice-bar
      left-icon="volume-o"
      mode="closeable"
      text="不同商户的订单，不能一次提交。请多次操作。"
    /> -->
    <div class="head-title">
      <div>
        {{ title }}
      </div>
      <van-divider class="line"></van-divider>
    </div>
    <slot name="search"></slot>
  </div>
</template>
<script>
export default {
  name: 'Head',
  props: {
    title: String
  }
}
</script>

<style scoped lang="less">
.head-text {
  .head-title {
    margin-top: 30px;
    font-size: 30px;
    padding: 0 10px;
  }
  .line {
    color: rgba(25, 156, 250, 0.98);
    border-color: #1995fa;
  }
}
</style>
